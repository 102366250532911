:root {
  --ld-match-preview-box-background: var(--ld-app-color-primary);
  --ld-match-preview-background-super-like: var(--ld-app-color-primary);
  --ld-match-preview-border-width: 2px;
  --ld-match-preview-border-radius: var(--ld-app-box-border-radius);
}

.superLike {
  object-fit: cover;
  max-height: 350px;
  background: var(--ld-match-preview-box-background);

  // default
  width: 100%;

  // create a border. example: new match
  border: var(--ld-match-preview-border-width) solid transparent;
  //width: calc(100% - ($borderWidth * 2)); // 8px didapat dari border width
  box-sizing: border-box;
  height: 65vw;
  border-radius: var(--ld-match-preview-border-radius);
}

.superLikeBadge {
  position: absolute;
  top: 8px;
  right: 13px;
  z-index: 2;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background-color: var(--ld-match-preview-background-super-like);
}

.leftScore {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 45px;
    height: 45px;
  }

  p {
    position: absolute;
    color: #fff;
    font-size: 14px;
  }
}

//
//.matchPercentageContainer {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//.matchIcon {
//  z-index: 1;
//  bottom: 37%;
//  right: 8%;
//  width: calc(45px + 2vw);
//}
//
//.matchPercentage {
//  z-index: 1;
//  bottom: 45%;
//  right: 10%;
//  color: white;
//}
