.Root {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    font-family: 'Nunito', sans-serif;
  }

  input {
    font-size: 1rem;
  }
}

input.MuiInput-input {
  border-radius: 4px;
}

// .buttonGroup {
//   width: 70%;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   column-gap: 10px;
// }

// .signUpButton {
//   display: flex;
//   text-align: center;
//   justify-content: center;
//   align-items: center;
//   color: black;
//   text-transform: capitalize;
//   gap: 16px;
//   width: 100%;
// }

// .title {
//   color: #4f2653;
//   text-align: center;
// }

// .subtitle {
//   text-align: center;
// }

// .smallText {
//   max-width: 250px;
//   margin-top: 8px;
// }

// .titleText {
//   color: #4f2653;
// }

// .subtitleText {
//   margin-bottom: 18px;
//   text-align: center;
// }

// .iconButton {
//   width: 50px;
//   position: relative;
// }
