@import '../scss/media-query';

.root {
  font-family: 'Nunito', sans-serif;
}

// prettier-ignore
div[id="react-joyride-step-0"] {
  div[class="react-joyride__tooltip"] {
    top: -100px;
  }
}

div[id='react-joyride-step-1'] {
  div[class='react-joyride__tooltip'] {
    left: -15px;
    top: 15vh;

    @include responsive(tablet) {
      left: 35px;
    }
  }
}

div[id='react-joyride-step-2'] {
  div[class='react-joyride__tooltip'] {
    right: -15px;
    top: 15vh;

    @include responsive(tablet) {
      right: 20px;
    }
  }
}

div[id='react-joyride-step-3'] div[class='react-joyride__tooltip'],
div[id='react-joyride-step-4'] div[class='react-joyride__tooltip'] {
  top: -20vh;
}

div[id='react-joyride-step-5'] div[class='react-joyride__tooltip'] {
  left: 0;

  @include responsive(tablet) {
    left: 70px;
  }
}

div[id='react-joyride-step-6'] div[class='react-joyride__tooltip'] {
  right: 0;

  @include responsive(tablet) {
    right: 70px;
  }
}

div[id='react-joyride-step-7'] div[class='react-joyride__tooltip'] {
  right: 30px;
  top: -30px;

  @include responsive(tablet) {
    right: 150px;
  }
}

div[id='react-joyride-step-0'] > div,
div[id='react-joyride-step-1'] > div,
div[id='react-joyride-step-2'] > div,
div[id='react-joyride-step-3'] > div,
div[id='react-joyride-step-4'] > div,
div[id='react-joyride-step-5'] > div,
div[id='react-joyride-step-6'] > div,
div[id='react-joyride-step-7'] > div,
div[id='react-joyride-step-8'] > div {
  pointer-events: none;
  cursor: pointer;
}

.swipeUp {
  animation: moveUp 2s infinite ease-in-out alternate;
}

.swipeLeft {
  animation: moveToLeft 2s infinite ease-in-out alternate;
}

.swipeRight {
  animation: moveToRight 2s infinite ease-in-out alternate;
}

.swipeRewind {
  animation: moveToBottomLeft 1.5s infinite ease-in-out alternate;
}

.swipeBoost {
  animation: moveToBottomRight 1.5s infinite ease-in-out alternate;
}

.swipeUpText {
  position: absolute;
  right: -50px;
  bottom: 0;
  width: 120px;
  text-align: left;
  display: block;
}

.tap {
  border: solid 1px white;
  border-radius: 8px;
  padding: 1rem;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.tapText {
  // position: absolute;
  // width: 120px;
  margin: 1.5rem auto;
}

// swipe left and right
.swipeText {
  position: absolute;
  text-align: left;
}

.swipeRightText {
  right: 0;
  width: 180px;
}

.swipeLeftText {
  left: 0;
  width: 150px;
}

.swipeRewindText {
  position: absolute;
  max-width: 100px;
  text-align: left;
  right: -100px;
  top: -50px;
}

.swipeBoostText {
  position: absolute;
  text-align: left;
  left: -150px;
  top: -90px;
  max-width: 200px;

  p {
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.swipeSearchText {
  text-align: left;
}

// animation bounce
@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
  100% {
    transform: translateY(0);
  }
}

// animation swipe to top and then to left and then back to the start
@keyframes moveToLeft {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(-20px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

// animation swipe to top and then to right and then back to the start
@keyframes moveToRight {
  0% {
    transform: translateY(0) translateX(0);
  }
  50% {
    transform: translateY(-20px) translateX(20px);
  }
  100% {
    transform: translateY(0) translateX(0);
  }
}

// animation swipe to bottom left
@keyframes moveToBottomLeft {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(20px) translateX(-20px);
  }
}

// animation swipe to bottom right
@keyframes moveToBottomRight {
  0% {
    transform: translateY(0) translateX(0);
  }
  100% {
    transform: translateY(20px) translateX(20px);
  }
}
