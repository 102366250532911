.usersTable {
  width: 100%;
  border-collapse: collapse;

  thead {
    th {
      position: sticky;
      top: 0;
      z-index: 3;
    }
  }

  th,
  td {
    border: 1px solid #ddd;
    padding: 6px;
    font-size: 14px;
  }

  tr:nth-child(even) {
    background-color: #f2f2f2;
  }

  th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--ld-app-color-primary);
    color: white;
  }

  tr {
    &.selected {
      background-color: #6ee7b7 !important;
      position: sticky;
      z-index: 2;
    }

    &.select {
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      &:hover {
        background-color: #ccc;
      }
    }
  }

  a {
    color: var(--ld-app-color-tertiary-darker);
    font-weight: 700;
  }
}

.btnGroup {
  display: flex;
  gap: 0.75rem;

  button {
    background-color: var(--ld-app-color-primary);
    color: #fff;
    border: 0;
    padding: 3px;
    width: 30px;
    height: 30px;
    border-radius: 6px;
    cursor: pointer;

    svg {
      width: 22px;
    }
  }

  .btnDelete {
    background-color: #c71616;

    &:disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}

.avatar {
  > div {
    width: 40px;
    height: 40px;
  }

  img {
    line-height: 0;
  }
}
