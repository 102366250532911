.nameContainer {
  //margin: 10px;
  //padding: auto;
  display: flex;
  flex-direction: column;
}

.required {
  font-size: 1rem;
  vertical-align: super;
  padding: 0 5px;
}
