.AppHeaderRoot {
  box-shadow: none;
  border-bottom: solid 1px #dcdcdc;
  //TODO: this now got changed from white to offwhite because of what MD generated, do we want to make it white?
  background-color: var(--ld-app-color-background) !important;
  color: var(--ld-app-body-text-color);
  top: var(--safe-area-top);
}

.AppHeaderWrapper {
  padding: 0 !important;
  justify-content: space-between;
  position: relative;
}

.headline {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  font-size: 1.125rem;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 200px; // width can be adjust if needed
}

.backColor {
  color: var(--ld-app-body-text-color-lightest);
}
