.card {
  position: relative;
  grid-template-columns: 1fr 1fr;
  border: 1px solid #dae0e6;
  height: 175px;
  background-color: white;
  border-radius: 8px;
  padding: 12px;
  flex: 0 0 75%;
  transition: all 0.2s linear;
  cursor: pointer;

  .review {
    color: #e7b822;
    font-size: 14px;
  }
  .checkmark {
    position: absolute;
    top: 12px;
    right: 12px;
    fill: #e7b822;
    transition: all 0.2s linear;
  }
  .text {
    .priceTitle {
      font-size: 24px;
      line-height: 32px;
    }
    .priceContainer {
      position: absolute;
      bottom: 12px;
      left: 12px;
    }
    .monthlyPrice {
      color: #a5acba;
      font-size: 14px;
      line-height: 20px;
    }
    .totalPrice {
      color: #141414;
      font-size: 16px;
      line-height: 24px;
    }
    .discount {
      background-color: #f7f9fe;
      color: #141414;
      border-radius: 5px;
      padding: 5px;
      width: max-content;
      position: absolute;
      right: 12px;
      bottom: 12px;
      font-size: 12px;
    }
  }
}
.selected {
  border: 1px solid #e7b822;
  transition: all 0.2s linear;
}
