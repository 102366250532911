.QuestionHeader {
  display: block;
  font-family: 'Nunito' !important;
  font-size: 36px !important;
  line-height: 44px !important;
  color: white;
  margin-bottom: 1rem !important;
  width: 500px;
}
@media (max-width: 680px) {
  .QuestionHeader {
    width: 400px;
    display: inline;
  }
}

@media (max-width: 320px) {
  .QuestionHeader {
    width: 200px;
  }
}
