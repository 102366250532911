.TextField {
  > input,
  > textarea {
    display: inline-block;

    box-sizing: border-box;
    //width: 320px;
    width: 100%;
    //margin: 1rem 0;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    padding: var(--ld-app-box-spacing);
    border-radius: var(--ld-app-box-border-radius);
    color: var(--ld-app-body-text-color);
    background: white;
    border: 1px solid transparent;

    &:focus,
    &:hover {
      //border-color: $primaryColor;
      //box-shadow: 0 0 0 3px $primaryColor;
      //border: 1px solid $gray200;
      border: 1px solid var(--ld-ref-palette-neutral-variant90);
      box-shadow:
        0px 1px 0px rgba(0, 0, 0, 0.3),
        0px 1px 1px 1px rgba(0, 0, 0, 0.15);
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  }

  &.error {
    > input,
    > textarea {
      border-color: var(--ld-app-color-error);
      box-shadow: 0 0 3px var(--ld-app-color-error);
    }
  }
}

.helperText {
  font-size: 0.85rem;
  color: var(--ld-app-color-error);
  margin: 0.5rem;
  font-family: 'Nunito', sans-serif;
}
