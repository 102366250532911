.CommunityPage {
  * {
    font-family: 'Nunito', sans-serif !important;
  }
}

.CommunityOverview {
  [class='cc-list__title'] {
    display: none;
  }
  [class='cc-list__header'] {
    padding: 25px 16px !important;
  }
  [class='cc-groups__subtitle-view'] {
    max-width: calc(100vw - 136px);
    text-overflow: ellipsis;
    overflow-x: hidden;
    > span {
      color: #aaa;
    }
  }
}

.communityWrapper {
  padding-left: 10px;
  padding-right: 10px;
}

.SpecificGroupChat {
  [class='cc-message-header'] {
    margin-left: 60px !important;
    width: calc(100% - 40px) !important;
    margin-top: 0 !important;
  }

  [class='cc-messages__header'] {
    height: auto !important;
  }

  [class='cc-message-header__wrapper'] {
    height: 70px !important;
  }

  [class='cc-messages'] {
    justify-content: unset !important;
  }

  [class='cc-list__content'] {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  [class='cc-messages__composer'] {
    width: 100vw !important;
    //height: auto !important;
    height: 153px !important;

    position: fixed;
    bottom: var(--safe-area-bottom);
    left: 0;
    border-radius: 0;
    border-top: 1px solid #e3e3e3;

    > div {
      padding: 12px !important;
      //bottom: var(--safe-area-bottom);
      position: relative;
      width: 100% !important;
      padding-bottom: 38px !important;
      height: 100%;
    }

    > cometchat-divider {
      display: none;
    }
  }
}

.communityContainer {
  display: block;
  padding-top: 120px;
}

.headerOverlay {
  position: absolute;
  left: 70px;
  right: 20px;
  height: 70px;
  background: none;
  cursor: pointer;
  z-index: 100;
}

.memberGroupCount {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0px;
  h4 {
    font-weight: 400;
    color: gray;
  }
  img {
    width: 24px;
    height: 24px;
  }
}

.MessageOverview {
  margin-top: 5px;
}

.communityList {
  flex-direction: row;
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 2px;
  margin-bottom: 20px;

  div {
    position: relative;
  }

  .communityImg {
    border-radius: 100px;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }
}

.communityText {
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  color: #00201f;
  cursor: pointer;
  padding: 0;
  outline: none;
  padding-bottom: 10px;
  &[aria-selected='true'] {
    border-bottom: 5px solid #008c8a;
  }

  h4 {
    font-weight: 400;
  }

  p {
    margin-top: 5px;
    color: #a5acba;
    font-size: 14px;
  }
}

.communityMember {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .memberImg {
    width: 100%;
    height: 100%;
  }
  p {
    color: #a5acba;
    font-size: 14px;
    margin-top: 5px;
  }
  .timeStamp {
    font-size: 17px;
  }
}

.badgeIcon {
  background-color: #f78aa3;
  color: #fff !important;
  border-radius: 100px;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

.communityLeft {
  align-items: center;
  justify-content: center;
  display: flex;
}

.onlineStatus {
  background-color: #f78aa3;
  color: #fff !important;
  border-radius: 100px;
  display: block;
  height: 8px;
  width: 8px;
  padding: 5px;
  top: 30px;
  right: 20px;
  border: 3px solid #fff;
}
.communityDetailsContainer {
  display: flex;
  padding: 1rem 2rem;
  margin-top: 80px;
  height: 50vh;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  h3 {
    text-align: center;
  }
  .textCommunity {
    margin-top: 25px;
    color: #a5acba;
    text-align: center;
  }
  p {
    color: #a5acba;
  }

  .onlineStatus {
    background-color: #f78aa3;
    color: #fff !important;
    border-radius: 100px;
    display: block;
    height: 8px;
    width: 8px;
    padding: 5px;
    top: -30px;
    position: relative;
    right: -30px;
    border: 3px solid #fff;
  }

  .imgCommunity {
    border-radius: 100px;
    width: 120px;
    height: 120px;
    object-fit: cover;
  }
  .flexSide {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 5px;
    }
  }
  .communityRules {
    margin-top: 30px;
    border: 1px solid #a5acba;
    padding: 15px;
    border-radius: 10px;
    display: block;
    width: 100%;

    .innerCommunityRules {
      margin: 5px 25px 25px 25px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }
    .headline {
      color: #222;
      margin-bottom: 25px;
      text-align: center;
    }
    ul {
      li {
        list-style: number;
        color: #222;
        margin-bottom: 15px;
      }
    }
  }
  .btnJoin {
    margin-top: 40px;
    background: #4ddad7;
    color: #fff;
    border-radius: 100px;
    width: 100%;
  }
}

.backCommunityChat {
  top: calc(10px + var(--safe-area-top));
}
