.Select {
  background-color: white;
  border-radius: 8px;
  box-sizing: border-box;
  min-height: calc(1.5em + 22px);
  min-width: 100px;
  padding: 12px;
  line-height: 1.5;
  text-align: left;
  font-size: 16px;
  border: 1px solid transparent;
  color: var(--ld-app-body-text-color-lighter);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;
  cursor: default;

  &:focus,
  &:hover {
    box-shadow:
      0px 1px 3px 1px rgba(0, 0, 0, 0.15),
      0px 1px 2px 0px rgba(0, 0, 0, 0.3);
  }
}

[disabled].Select {
  opacity: 0.5;
  cursor: not-allowed;
}

.CustomSelectOption {
  color: var(--ld-app-body-text-color-lighter);
  list-style: none;
  padding: 8px;
  font-family: 'Nunito';
  background-color: white;

  &:focus,
  &:hover {
    //background-color: $primaryColor;
    background-color: var(--ld-app-color-tertiary-lighter);
    color: white;
  }
}

.CustomSelectListbox {
  min-width: 100px;
  max-height: 300px;
  overflow-y: scroll;
  border-radius: 8px;
  margin-top: 3px;
  box-shadow:
    0px 1px 3px 1px rgba(0, 0, 0, 0.15),
    0px 1px 2px 0px rgba(0, 0, 0, 0.3);
}

.CustomSelectPopper {
  position: absolute;
  z-index: 10;
}

.placeholder {
  color: var(--ld-app-body-text-color-lighter);
}
