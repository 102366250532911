.premiumActivate {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 2rem;
  height: 75vh;
  text-align: center;

  p {
    margin-top: 10px;
  }
}

.congratulations {
  height: 350px;
  width: 350px;
  margin: 0 auto;
}

.btn {
  margin-top: 30px;
  background: var(--ld-ref-palette-primary80);
  width: 100%;
  color: #fff;
}
