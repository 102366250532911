.ProfileDetail {
  scroll-behavior: smooth;
  overflow-y: auto;
  color: var(--ld-app-body-text-color);
  font-family: 'Nunito';
  width: 100%;
  padding-bottom: 10px;
  //32px for the box
  //66 is the height of the footer
  font-size: 16px;
  line-height: 24px;
  padding: 0 1.5rem;
}
.smallButtonsGroup {
  flex-direction: column;
  display: flex;
  position: absolute;
  right: 0px;
  bottom: 100px;
}

.popupContainer {
  margin: 5px;
  position: fixed;
  bottom: 0;
}

.popupButton {
  background-image: linear-gradient(
    to bottom,
    #f2e6c0,
    #f0db9c,
    #edd078,
    #eac452,
    #e7b822
  );
  width: 100% !important;
  color: #222 !important;
}

.popupHeader {
  text-align: center;
  margin-bottom: 20px;
}

.popupContent {
  border: 1px solid #a5acba;
  padding: 10px;
  margin: 20px 0;
  border-radius: 5px;

  .smallHeading {
    color: #e7b822;
    font-size: 15px;
  }
  .subFlex {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .subtle {
    color: #a5acba;
    font-size: 14px;
    margin-bottom: 5px;
  }
  h3 {
    padding-top: 10px;
    padding-bottom: 35px;
  }

  .smallTextHeader {
    color: #a5acba;
    text-align: center;
    border: 1px solid #a5acba;
    font-size: 14px;
    padding: 5px;
    margin-bottom: 10px;
  }
  .premiumList {
    list-style-image: url('/images/checkMark.png');
    padding-left: 20px;
    .subtle {
      color: #a5acba;
    }
    li {
      margin-bottom: 5px;
      text-indent: 5px;
      ::marker {
        text-indent: 5px;
      }
      p {
        font-size: 12px;
      }
    }
  }
}

.offset {
  left: -8px;
  position: relative;
}

.formLabel {
  margin-top: 20px;
}

.warningUser {
  background-color: red;
  color: #222;
}

.MoreProfileDetail {
  background-color: white;
  padding: 16px 8px;
  display: flex;
  gap: 8px;
  flex-direction: column;
}

.matchScore {
  display: block;
  color: var(--ld-app-body-text-color-lightest);
  font-size: 18px;
  white-space: nowrap;
  margin-top: 26px; //16+10 which is currently the space underneeth
  .matchScoreIcon {
    width: 48px;
    height: 48px;
    margin-bottom: 3px;
    background-image: url('/images/quantum-score.png');
    background-size: contain;
    background-position: center;
    display: inline-block;
    margin-right: 10px;
    background-repeat: no-repeat;
    vertical-align: middle;
  }

  .score {
    color: var(--ld-app-body-text-color);
    font-size: 22px;
    margin-right: 10px;
  }
}

.BasicDetailContent {
  display: flex;
  gap: 8px;
  align-items: center;
  background-color: white;
  flex-wrap: wrap;

  > * {
    cursor: default;
  }
}

.BasicDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
  position: relative;

  img {
    width: 20px;
    height: 20px;
  }
}

.MoreDetailContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
}

.BasicDetail {
  display: flex;
  gap: 8px;
  background: #f3f3f3;
  border-radius: 16px;
  backdrop-filter: blur(2px);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.chipValues {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.LikeDislikeFooter {
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.MoreDetails {
  display: inline-flex;
  width: fit-content;
  margin: 0 8px 8px 0;
  background: #f3f3f3;
  font-size: 16px;
  border-radius: 16px;
  padding: 5px 10px;
  align-items: center;
  cursor: default;
  color: var(--ld-app-body-text-color);
  gap: 8px;
}

.FooterContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
}

.ProfileDetailsFooter {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin-bottom: 19px;

  button {
    background-color: transparent !important;
    border: none !important;
    transform: scale(1);
    transition: all 0.5s ease;

    &:hover,
    &:hover::before {
      cursor: pointer;
      transform: scale(1.2);
      transition: all 0.5s ease;
    }
  }
}

.pendingLike,
.pendingDislike,
.pendingDetails {
  //zoom in 20%
  transition: all 0.5s;
  transform: scale(1.35);
}

.SignContainer {
  background: #f3f3f3;
  border-radius: 16px;
  backdrop-filter: blur(2px);
  display: flex;
  padding: 5px 10px;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
}

.SignIcon {
  font-size: 18px;
}

.upArrow {
  transform: rotate(180deg);
  position: absolute;
  top: 0;
  right: 30px;
  cursor: pointer;
  background: url('/images/DownArrowSwipe.svg'), white;
  background-position: center;
  background-repeat: no-repeat;
  width: 32px;
  height: 32px;
  border-radius: 50%;
}

.downArrow {
  position: absolute;
  bottom: 80px;
  left: auto;
  right: auto;
  padding: 8px 16px;
}

.flexSide {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  margin-bottom: 2rem;
  button {
    //margin-top: 10px;
    //width: 100%;
  }
}
.reportUser,
.blockUser {
  margin-right: 5px;
  height: 24px;
  width: 24px;
}

.reportUserPopup {
  position: absolute;
  margin-top: 20px;
  right: 10px;
  button {
    margin-right: 10px;
  }
}
.reportedInput {
  margin-top: 20px;
}
