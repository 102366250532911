.ProfileCardImageContainer {
  line-height: 0;
  height: 100%;
  background: #f4f4f4;
  z-index: 10;

  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }

  position: relative;
}

.ProfileCard {
  width: 100%;
  //100% minus the height of the buttons at the bottom, and twice the padding/spacing above and below the buttons (12) and 1x padding
  height: calc(
    100% - 100px - (2 * var(--ld-ref-spacing-l)) - var(--ld-app-box-spacing)
  );
  transition: opacity 0.15s;
  overflow: hidden;
  position: relative;

  .loading,
  .outOfCards {
    text-align: center;
    font-size: 20px;
  }

  &.draggableProfile {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.5s;
  }
}

.titleWrap {
  display: flex;
  gap: 0.75rem;
}

.stamp {
  position: absolute;
  top: 50px;
  z-index: 5;
  font-size: 30px;
  padding: 20px;
  border: solid 5px #fff;
  opacity: 0;
  font-weight: 700;
  transform: rotate(0deg);
  box-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 0px rgba(0, 0, 0, 0.2);

  &.active {
    opacity: 1;
    transition: all 0.2s ease-in-out;
  }

  &.likeStamp {
    left: 30px;
    border-color: #64ffa7;
    color: #66ffa4;
    transform: rotate(-25deg);
  }

  &.dislikeStamp {
    border-color: #07d5ff;
    color: #07d5ff;
    right: 30px;
    transform: rotate(25deg);
  }

  &.detailsStamp {
    border-color: #d6cbff;
    color: #d5c9ff;
    left: calc(50% - 80px);
    top: 40%;
    transform: rotate(10deg);
  }
}

.ProfileCardTextContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 40px 30px 40px 30px;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) 8.33%,
    rgba(0, 0, 0, 0.35) 17.19%
  );
  color: white;
  z-index: 11;

  &.textElevate {
    min-height: 260px;
  }
}

.MainProfileIcon {
  display: flex;
  gap: 5px;
  align-items: flex-start;
  color: white;

  img {
    filter: invert(1);
  }
}

.genderIcon {
  margin-left: -3px;

  img {
    width: 25px;
  }
}

.occupationIcon {
  img {
    margin-top: 2px;
    margin-right: 4px;
  }
}

.tapLeft {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 20%;
  cursor: w-resize;
}

.tapRight {
  cursor: e-resize;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 20%;
}
