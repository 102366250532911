.Container {
  display: block;
  // padding: 0 15px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  // position: relative;
}
.home,
.resetPadding {
  padding-left: 0;
  padding-right: 0;
}
