:root {
  --ld-button-filled-primary-font-color: var(--ld-app-color-on-primary);
  --ld-button-filled-primary-border-color: var(--ld-app-color-primary);
  --ld-button-filled-primary-background-color: var(--ld-app-color-primary);
}
.pageContainer {
  background-color: white;
  position: relative;
  margin: -1rem;

  * {
    font-family: 'Nunito', sans-serif;
  }
}
.carouselContainer {
  margin: 20px -15px 0 -15px;
  & > p {
    margin-left: 15px;
  }
}

.iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
}

.promoContainer {
  position: relative;

  img {
    position: absolute;
    top: 7px;
    right: 90px;
    width: 15px;
    height: 15px;
    object-fit: contain;
  }

  .inputPromo {
    width: 100%;
    padding-left: 5px;
    border: 1px solid #a5acba;
    border-radius: 4px;

    input {
      padding: 5px;
    }
  }
  p {
    position: absolute;
    top: 5px;
    right: 10px;
    font-size: 14px;
    color: #357cff;
  }
  p:hover,
  img:hover {
    cursor: pointer;
  }
}

.errorPromo {
  color: red;
  font-size: 12px;
  margin-left: 5px;
}

.overlayContent {
  background-color: #ffff;
}

.cardCarousel {
  display: flex;
  flex-wrap: nowrap;
  overflow-x: scroll;
  gap: 8px;
  padding-left: 15px;
  padding-right: 15px;
  margin: 8px 0 1rem 0;
  scroll-behavior: smooth;
  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
}

.closeBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background-color: transparent !important;
}

.iconContainer {
  border-radius: 50%;
  background-color: #07232f;
  width: 22px;
  height: 22px;
}

.benefits {
  margin-top: 1rem;
  padding: 8px 20px 20px 20px;
  background-color: white;
  border: 1px solid #a5acba;
  border-radius: 8px;
  h6 {
    color: #a5acba;
    border: 1px solid #a5acba;
    margin-left: 50%;
    transform: translateX(-50%);
    width: max-content;
    padding: 5px;
    border-radius: 5px;
    font-weight: 400;
    font-size: 12px;
  }

  ul {
    margin-top: 1rem;
    margin-left: 20px;
    // line-height: 3rem;
    // gap: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  ul li {
    list-style-type: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  ul li:not(&::before) {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  ul > li::before {
    content: '';
    height: 1em;
    width: 1em;
    display: block;
    float: left;
    margin-left: -1.5em;
    margin-top: 5px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
    transition: background-size 0.3s;
    -webkit-transition: background-size 0.3s;
  }
  ul > li::before {
    background-image: url('/images/checkmark.svg');
    // margin-top: 16px;
  }
  p:nth-child(2) {
    color: #a5acba;
    line-height: 20px;
  }
}
.contentContainer {
  padding: 16px 16px 0 16px;
  overflow-y: scroll;
  padding-bottom: calc(8.33rem + var(--safe-area-bottom, 0));
  height: 80vh;

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 1.4;
    color: #141414;
  }
}
.footer {
  font-size: 11px;
  line-height: 11px;
  color: #a5acba;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 50;

  .footerWrapper {
    border-top: 1px solid #a5acba;
    background-color: white;
    max-width: 600px;
    margin: auto;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    padding-bottom: calc(1rem + var(--safe-area-bottom, 0));

    &__top {
      margin-bottom: 1rem;
    }
    &__bottom {
      display: flex;
    }
    &__left {
      display: flex;
      flex: 0 0 50%;
      align-items: center;
      gap: 12px;
      .text {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        p:first-child {
          font-size: 14px;
          color: #a5acba;
          line-height: 14px;
        }
        p:nth-child(2) {
          font-size: 14px;
          color: #141414;
          line-height: 20px;
        }
      }
      img {
        width: 32px;
        height: 32px;
        background-color: #07232f;
        padding: 5px;
        border-radius: 50%;
      }
    }
    &__right {
      background-image: linear-gradient(#f2e6c0, #e7b822);
      color: black !important;
      border: 1px solid white !important;
      flex: 0 0 50%;

      div {
        position: relative;
        top: 0;
        margin: 0 auto;
        left: 0;
      }
    }
  }

  a {
    color: initial;
    text-decoration: underline;
  }
}
.header {
  display: flex;
  justify-content: center;
  gap: 10px;
  position: relative;
  //for browser that don't support gradient
  background-color: #f2e6c0;
  background-image: linear-gradient(
    (rgb(242, 230, 192, 25%)),
    (rgb(231, 184, 34, 25%))
  );
  padding: 20px 0;
  //TODO: make the side of header gradient as well
  h1 {
    font-size: 16px;
  }
}

.successOverlay {
  display: flex;
  justify-content: center;
  line-height: 26px;
  align-items: center;
  .successOverlay__content {
    display: block;
    h3 {
      margin-top: 35px;
      padding-bottom: 10px;
    }
    hr {
      margin-bottom: 10px;
    }
  }
}

.downloadAppModal {
  text-align: center;
  padding: 2rem 1.5rem;
  max-width: 400px;
  font-family: 'Nunito', sans-serif;

  h3 {
    margin-bottom: 0.5rem;
    display: block;
  }
}

.downloadAppGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  gap: 1rem;

  img {
    height: 50px;
    width: 150px;
    max-width: 100%;
  }
}

.promoCodeBtn {
  background-color: transparent;
  border: 0;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  color: var(--ld-app-hyperlink-font-color);

  &:disabled {
    color: #a5acba;
    cursor: not-allowed;
  }
}

.promoSuccessApply {
  padding: 5px 10px;
  display: flex;
  justify-content: space-between;

  p {
    font-size: 13px;
    color: #22a222;
  }
}
