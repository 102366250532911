.DescreteProgressBar {
  display: flex;
  width: calc(100% - 30px);
  position: absolute;
  top: 4px;
  height: auto;
  gap: 6px;
  margin: 5px 15px;
  flex-direction: row;
  align-items: stretch;
}

.DescreteProgressBarItem {
  height: 4px;
  border-radius: 4px;
  background-color: #c5c5c540;
  transition: background-color 0.2s ease-in-out;
  flex: 1;
}

.DescreteProgressBarItemActive {
  background-color: white;
}
