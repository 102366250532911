:root {
  --ld-button-text-primary-hover-background-color: var(
    --ld-ref-palette-primary95
  );
  --ld-button-text-secondary-hover-background-color: var(
    --ld-ref-palette-secondary98
  );
  --ld-button-text-tertiary-hover-background-color: var(
    --ld-ref-palette-tertiary95
  );

  --ld-button-border-radius: var(--ld-ref-border-radius-xl);
  --ld-form-border-radius: var(--ld-ref-border-radius-xl);
  --ld-button-filled-secondary-background-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-border-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-font-color: var(
    --ld-ref-palette-neutral-variant100
  );
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-neutral-variant30
  );

  --ld-button-filled-primary-hover-background-color: var(
    --ld-ref-palette-primary50
  );

  --ld-button-border-radius: var(--ld-ref-border-radius-xl);
  --ld-button-filled-secondary-background-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-border-color: var(
    --ld-ref-palette-neutral-variant90
  );
  --ld-button-filled-secondary-font-color: var(
    --ld-ref-palette-neutral-variant20
  );
  --ld-button-filled-secondary-hover-background-color: var(
    --ld-ref-palette-neutral-variant80
  );

  // signinWithPassword component color variable overwrites
  --ld-auth-text-hyperlink: var(--ld-ref-palette-neutral100);
  --ld-auth-form-border-radius: var(--ld-ref-border-radius-xl);
  --ld-auth-text-color-error: var(--ld-ref-palette-error60);
  --ld-auth-modal-border-radius: var(--ld-ref-border-radius-xl);
  --ld-auth-modal-background-color: var(--ld-ref-palette-neutral-variant95);
  --ld-slider-color: var(--ld-app-color-secondary-darker);
  --ld-paragraph-text-color-primary: var(--ld-ref-palette-neutral35);

  // match preview
  --ld-match-preview-box-background: radial-gradient(
        126.42% 100% at 0% 100%,
        rgba(22, 169, 247, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        79.68% 79.68% at 0% 0%,
        rgba(255, 217, 69, 0.6) 0%,
        rgba(255, 217, 69, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)),
    linear-gradient(0deg, #ffffff, #ffffff);
  --ld-match-preview-background-super-like: rgba(77, 218, 216, 0.85);

  // signin input
  --ld-auth-form-border-radius: var(--ld-ref-spacing-m);
}
