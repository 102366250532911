@import '../scss/variables-components';

.root {
  width: 100vw;
  height: 100vh;
  font-family: 'Nunito', sans-serif;
  position: absolute;
  left: 0;
  box-sizing: content-box;
  background: white;
  overflow: scroll;
  position: fixed;
}

.wrapper {
  display: flex;
  padding: 1rem;
  align-items: center;
  justify-content: space-between;
}

.toMergeSection {
  position: sticky;
  bottom: -3rem;
  text-align: center;
  transition: all 0.2s ease-in-out;
  opacity: 0;
  visibility: hidden;
  display: flex;
  justify-content: center;
  gap: 1rem;

  &.show {
    bottom: 1rem;
    opacity: 1;
    visibility: visible;
  }
}

.selectRow {
  display: flex;
  gap: 1rem;
}

.selectColumn {
  background: #fff;
  padding: 1.5rem;
  flex: 0 0 50%;
  border: solid 1px #ccc;
  border-radius: 4px;
}

.mergeSuccess {
  border: solid 2px var(--ld-app-color-primary);
  text-align: center;
  padding: 1rem;
  max-width: 400px;
  margin: 2rem auto;
  border-radius: 4px;
}
