.ContinueButton {
  position: fixed;
  right: 10vw;
  bottom: 50px;
  width: auto;
  text-align: right;
  padding: 0;
  box-sizing: border-box;

  //background: rgb(231, 180, 246);
  //background: linear-gradient(0deg, rgba(231, 180, 246, 1) 0%, rgba(231, 180, 246, 1) 67%, rgba(231, 180, 246, 0) 100%);

  z-index: 10;

  //only with this class do we render a background for it to make sure it doesnt
  //get mixed with the page content (handy on scrolling pages where the button also has text next to it)
  &.withBackground {
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    text-align: right;
    padding: 80px 10vw 50px 3vw;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 2400%;
    //same as in App.scss
    animation: gradient 40s ease infinite;

    margin: 0px;

    -webkit-mask-image: linear-gradient(transparent, black, black, black);
    mask-image: linear-gradient(transparent, black, black, black);

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100vh;
      background: url(/images/noise.svg);
      filter: contrast(150%) brightness(800%);
      z-index: 11;
    }
  }

  > * {
    z-index: 12;
    position: relative;
  }

  p {
    max-width: calc(100vw - 110px);
    display: inline-block;
    text-align: right;
    padding-right: 16px;
    vertical-align: middle;
    color: white;
  }
}

.chevronNext {
  vertical-align: middle;
  //background-color: #07232F !important;
  background-color: white !important;

  svg {
    color: #07232f;
  }

  &:hover {
    border-radius: 100%;
    background-color: white;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.25);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: -1;
      animation: ripple 1.5s ease-out infinite;
    }
  }
}

@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .noise {
    filter: contrast(150%) brightness(800%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
