@import '../scss/media-query';

.root {
  position: relative;
  top: 80px;
  min-height: calc(
    100vh - 80px - var(--safe-area-bottom, 0) - var(--safe-area-top, 0)
  );
  background-color: #f8f9fb;
  padding-bottom: 80px; // add more space on the bottom for web platform

  min-height: calc(100vh - 80px);
  background-color: #f8f9fb;
  * {
    font-family: 'Nunito', sans-serif;
  }
}
.mainProfilePictureContainer {
  cursor: pointer;
  background-color: #fff;
  padding-bottom: 24px;
}
.profilePictureContainer {
  position: relative;
  max-width: 128px;
  margin: auto;
}
.profilePicture {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 50%;
  transform: translateX(-50%);
}
.editIconContainer {
  background-color: #f8f9fb;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  padding: 12px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
}

.notification {
  background-color: #f78aa3;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  position: absolute;
  top: 2px;
  right: 3px;
}
.usernameContainer {
  text-align: center;
  font-size: 24px;
  margin-top: 1rem;
}

.benefitCard {
  position: relative;
  background-color: #ffffff;
  border-radius: 8px;
  text-align: center;
  flex: 1;
  padding: 15px 8px;
  transition: all 0.2s ease-in-out;
  outline: solid 1px #efeff1;

  &:hover {
    cursor: pointer;
    box-shadow: 0 4px 2px -2px rgb(196, 194, 194);
  }

  @include responsive(mobile) {
    padding: 25px 10px;
  }

  @include responsive(tablet) {
    padding: 35px 25px;
  }

  img {
    width: 32px;
    height: 32px;
    margin-bottom: 10px;
  }
  p {
    color: #a5acba;
    margin-bottom: 5px;
    font-size: 15px;
    &.subText,
    span {
      color: #4ddad7;
    }

    &.subText {
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}

.cardContainer {
  display: flex;
  gap: 12px;
}

.banner {
  margin-left: 50%;
  transform: translateX(-50%);
  background-color: white;
  width: 100%;
  margin-top: 20px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 16px;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    inset: 0;
    border-radius: 8px;
    padding: 2px;
    background: linear-gradient(#f2e6c0, #e7b822);
    -webkit-mask:
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .title {
    display: flex;
    align-items: center;
    gap: 12px;
    img {
      padding: 5px;
      background-color: #07232f;
      border-radius: 50%;
    }
    p {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
    }
  }
  .seeLikes {
    color: #a5acba;
  }
}
.upgradeButton {
  background-color: white !important;
  color: #e7b822 !important;
  border: white !important;
  box-shadow: 0 4px 2px -2px rgb(196, 194, 194);
  &:hover {
    cursor: pointer;
  }
}
.addPremiumFeatureIcon {
  background-color: rgb(219, 224, 230, 50%);
  border-radius: 50%;
  padding: 7px;
  position: absolute;
  top: -6px;
  right: -6px;
}
.premiumContent {
  background-color: #f7f7f8;
  padding: 24px;
  padding-bottom: calc(24px + var(--safe-area-bottom, 0));
  min-height: 100%;
}

.editProfile {
  font-size: 0.938rem;
}

.upgradeSuccess {
  position: relative;
  top: -80px;
  background: #f8f9fb;
}
