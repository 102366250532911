@import '../scss/media-query';

.FooterMenuRoot {
  position: fixed;
  bottom: calc(8px + var(--safe-area-bottom, 0));
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center; /* center horizontally */
  align-items: center; /* center vertically */
  width: 100% !important;
  z-index: 4;
}

.FooterMenu {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  border-radius: 80px;
  position: relative;
  padding: 0.75rem 1rem;
  background-color: #fff;
  box-shadow:
    0 1px 3px 0 rgb(0 0 0 / 0.1),
    0 1px 2px -1px rgb(0 0 0 / 0.1);

  @include responsive(tablet) {
    padding: 0.75rem 1.5rem;
  }
}

.wrapper {
  padding-left: 15px;
  padding-right: 15px;
}

.container {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(77, 218, 216, 0.2);
  transition: transform 0.2s ease-in-out;
  padding: 10px;
  position: relative;

  &.active {
    transform: scale(1.2);
  }

  &.inactive {
    &:hover {
      transform: scale(1.1);
    }
  }
}

.pink {
  background-color: rgba(247, 138, 164, 0.2);
}

.badge {
  display: block;
  //notification
  &:hover > p {
    transform: translate(10px, -10px);
    transition: transform 0.2s ease-in-out;
  }

  &:hover > a {
    transform: scale(1.2);
    transition: transform 0.2s ease-in-out;
  }
}
