.ProfileQuestionContainer {
  padding: 8px 0px;
}

.QuestionContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QuestionDetail {
  color: var(--ld-app-body-text-color-lightest);
  padding: 5px 0;

  svg {
    color: #dcdcdc;
  }
}

.QuestionText {
  font-family: 'Nunito';
  font-size: 16px;
  color: #141414;
}

.AnswerAvatar {
  width: 100px;
  height: 100px;
}

.valuesContainer {
  //border: 1px solid $gray50;
  border: 1px solid var(--ld-ref-palette-neutral-variant95);
  padding: 8px;
  border-radius: var(--ld-app-box-border-radius);
  display: flex;
  flex-direction: row;
  max-width: 100%;
  flex-flow: wrap;
  box-sizing: border-box;
  gap: 8px;
  margin-top: 8px;
}

.Question {
  display: flex;
  gap: 5px;
  min-width: 60%;
}
