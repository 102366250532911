.bubble {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: #f78aa3;
  color: white;
  border-radius: 50%;
  text-align: center;
  z-index: 5;
  top: 0;
  right: -5px;

  &__inactive {
    transition: transform 0.2s ease-in-out;
  }

  &__active {
    transform: translate(10px, -3px);
    transition: transform 0.2s ease-in-out;
  }
}
