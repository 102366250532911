.root {
  background-color: #f5f6f7;
  border-radius: 9999px;
  margin: 0;
  overflow: hidden;

  &.size {
    height: 64px;
    width: 64px;
  }

  &.medium {
    height: 95px;
    width: 95px;
  }

  &.large {
    height: 128px;
    width: 128px;
  }

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.wrap {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}
