.PhotoQuestion {
  display: block;

  .uploader {
    width: 200px;
  }

  .helpertext {
    color: var(--ld-app-color-error);
    font-size: 1.1rem;
    margin-top: 1rem;
  }
}
