.BackButton {
  position: fixed;
  left: 20px;
  top: calc(20px + var(--safe-area-top));
  //width: 30%;
  text-align: left;
  //padding: 80px 10vw 50px 3vw;
  //left: 0;
  //top: -60px;
  //width: 30%;
  //text-align: left;
  //padding: 80px 10vw 50px 3vw;

  box-sizing: border-box;
  white-space: wrap;
  // background: rgb(231,180,246);
  // background: linear-gradient(0deg, rgba(231,180,246,1) 0%, rgba(231,180,246,1) 67%, rgba(231,180,246,0) 100%);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;

  &.relative {
    position: relative;
    margin: 1rem 0;
    width: min-content;
    left: 0;
    top: 0;
  }

  > * {
    //max-width: calc(100% - 90px);
    //display: inline-block;
    text-align: left;
    padding-left: 16px;
    align-self: start;
    margin: 0px !important;
    margin-bottom: 0px !important;
    text-wrap: wrap;
  }

  &.small {
    .chevronBack {
      padding: 5px;
    }
  }
}

.chevronBack {
  vertical-align: middle;
  //background-color: #07232f !important;
  //background-color: $neutral300 !important;
  background-color: var(--ld-ref-palette-neutral-variant98) !important;
  padding: 0px;
  margin-top: 8px !important;
  svg {
    //color: white;
    //color: var(--ld-app-body-text-color-light);
    color: var(--ld-ref-palette-neutral70);
    // width: 50px;
    // height: 50px;
  }

  &:hover {
    border-radius: 100%;
    //background-color: $neutral500 !important;
    background-color: var(--ld-ref-palette-neutral95) !important;
    box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.25);

    &::after {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: inherit;
      border-radius: 50%;
      z-index: -1;
      animation: ripple 1.5s ease-out infinite;
    }
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
