.EditProfileDetailContainer {
  padding: 1rem 1rem 1.5rem 1rem;
  margin-top: 0rem;
}
.rangeSlider {
  background-color: white;
  padding: 20px;
  min-width: 300px;
  min-height: 70px;
  color: var(--ld-app-body-text-color) !important;
  border-radius: var(--ld-ref-border-radius-l);
}
