:root {
  //default values for the safe area
  // on WEB this will be the padding for the root div
  // on mobile these variables will be overwritten by the SafeArea Capacitor plugin
  --safe-area-top: 0px;
  --safe-area-bottom: 0px;
  --safe-area-left: 0px;
  --safe-area-right: 0px;
}

// * {
//   outline: 1px solid #f00;
// }

//reset margins and paddings of all elements
*,
*::before,
*::after {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

select {
  display: inline-block;

  box-sizing: border-box;
  //width: 320px;
  width: 100%;
  //margin: 1rem 0;
  font-family: 'Nunito Sans', sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: var(--ld-app-box-spacing);
  border-radius: var(--ld-app-box-border-radius);
  color: var(--ld-app-body-text-color);
  background: white;
  border: 1px solid transparent;

  appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 1rem center;
  background-size: 1em;

  &:focus,
  &:hover {
    //border: 1px solid $gray200;
    border: 1px solid var(--ld-ref-palette-neutral-variant90);
    //TODO replace with something like --ld-ref-shadow-sm
    box-shadow: var(--ld-ref-shadow-s);
    //box-shadow:
    //  0px 1px 0px rgba(0, 0, 0, 0.3),
    //  0px 1px 1px 1px rgba(0, 0, 0, 0.15);
  }

  // firefox
  &:focus-visible {
    outline: 0;
  }
}

.error {
  color: white;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  * {
    font-family: 'Nunito', sans-serif;
  }

  img {
    max-width: 100px;
    margin-bottom: 2rem;
  }

  .content {
    max-width: 37.5rem;
    text-align: center;
    padding: 1rem;
  }

  h1,
  h3 {
    line-height: 1.2;
  }

  h1 {
    margin-bottom: 1rem;
  }

  h3 {
    font-weight: 500;
  }

  .groupButton {
    margin-top: 2.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    flex-direction: column;

    @media (min-width: 560px) {
      flex-direction: row;
      padding: 0 3rem;
    }
  }

  pre {
    white-space: pre-wrap;
    padding: 1rem;
  }
}

body {
  width: 100%;
}

a,
a:hover {
  color: #7dfffb;
  text-decoration: none;
}

.App {
  min-height: 100vh;
  box-sizing: border-box;
}

/* Chrome-specific */
@media all and (-webkit-min-device-pixel-ratio: 0) and (min-resolution: 0.001dpcm) {
  .noise {
    filter: contrast(150%) brightness(800%);
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

//Page Styles. Classes for each route
.pageDefault {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 40s ease infinite;
  margin: 0px;
  background-attachment: fixed;
  position: relative;
  overflow-x: hidden;

  > * {
    z-index: 1;
    position: relative;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url(/images/noise.svg);
    filter: contrast(150%) brightness(800%);
    z-index: 0;
  }
}

.chat {
  overflow-y: hidden;
}

.matches,
.home,
.profile,
.edit_profile,
  //.edit_profile_slug,
.subscription,
.contact,
.terms,
.admin,
.community,
.community_details_group
.matches_and_likes,
.friends_matches_likes,
.chat,
.privacy_policy,
.browse_dates,
.browse_friends,
.events {
  background: white;

  a,
  a:hover {
    color: var(--ld-app-hyperlink-font-color);
    text-decoration: none;
  }
}

.signin {
  background-image: url('/images/bg_landing.jpg');
  background-size: cover;
  background-position: center;
  @media (min-width: 768px) {
    background-image: url('/images/bg_landing_desktop.jpg');
  }
}

.house_rules,
.welcome {
  background-image: url('/images/bg_houserules.jpg');
  background-size: cover;
  background-position: center;
  @media (min-width: 768px) {
    background-image: url('/images/bg_houserules_desktop.jpg');
  }
}
