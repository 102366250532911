.blockDetail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 5px 0;
  font-size: 14px;

  &.rows {
    flex-direction: row;
  }

  img {
    width: 20px;
    height: 20px;
  }
}

.blockMore {
  display: inline-flex;
  width: fit-content;
  margin: 0 8px 8px 0;
  background: #f3f3f3;
  border-radius: 16px;
  padding: 5px 10px;
  align-items: center;
  cursor: default;
  color: var(--ld-app-body-text-color);
  gap: 8px;
  font-size: 13px;
}

.chipValues {
  display: flex;
  flex-direction: row;
  gap: 8px;

  span {
    font-size: 13px;
  }
}

.basicDetail {
  display: flex;
  gap: 8px;
  background: #f3f3f3;
  border-radius: 16px;
  backdrop-filter: blur(2px);
  padding: 5px 10px;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.signContainer {
  background: #f3f3f3;
  border-radius: 16px;
  backdrop-filter: blur(2px);
  display: flex;
  padding: 5px 10px;
  flex-wrap: wrap;
  gap: 10px;
  align-items: center;
  font-size: 13px;
}

.signIcon {
  font-size: 15px;
}

.profileDetail {
  margin: auto;
  max-width: 600px;
}

.profileContent {
  font-size: 15px;
  margin-bottom: 1.5rem;

  p {
    margin-bottom: 0.5rem;
  }
}
