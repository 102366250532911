.ProfilePreview {
  display: block;
}

$borderRadius: 0px;

.profileCard {
  background-color: var(--md-sys-color-primary-container);
  position: relative;
  border-radius: var(--lincd-sys-border-radius);
  overflow: hidden;
  width: 100%;
  max-height: 250px;

  @media only screen and (min-width: 600px) {
    max-height: 350px;
  }
}

.profileCardImage {
  position: relative;
  flex: 1;
}

.profileCardImageWrap {
  line-height: 0;
  width: 100%;
  display: flex;
}

.profileImage {
  object-fit: cover;
  max-height: 250px;
  width: 100%;
  //background: radial-gradient(126.42% 100% at 0% 100%, rgba(22, 169, 247, 0.4) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(79.68% 79.68% at 0% 0%, rgba(255, 217, 69, 0.6) 0%, rgba(255, 217, 69, 0) 100%), radial-gradient(100% 100% at 100% 100%, rgba(162, 1, 239, 0.35) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)), linear-gradient(0deg, #FFFFFF, #FFFFFF);
  //border: 4px solid transparent;
  //width: calc(100% - 8px);
  //border-radius: 30px;

  @media only screen and (min-width: 600px) {
    max-height: 350px;
  }
}

.profileText {
  font-weight: 700;
  color: white;
  font-size: 1rem;
}

.profileDataContainer {
  z-index: 3;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: inherit;
  //TODO: box padding
  padding: 13px;
  box-sizing: border-box;
}

.profileSmallText {
  color: white;
  font-weight: 400;
  font-size: 0.85rem;
}

//
//.profilePercentageContainer {
//  display: flex;
//  justify-content: center;
//  align-items: center;
//}
//
//.profileIcon {
//  z-index: 1;
//  bottom: 37%;
//  right: 8%;
//  width: calc(45px + 2vw);
//}
//
//.profilePercentage {
//  z-index: 1;
//  bottom: 45%;
//  right: 10%;
//  color: white;
//}
