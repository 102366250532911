.termsContainer {
  // width:80%;
  // margin: auto;
  font-family: 'Roboto';
  line-height: 1.5;
  padding: 1rem;
}

.subtitle {
  text-align: center;
}

.termsContainer {
  p,
  li {
    margin: 20px 0 20px 0;
  }

  ol {
    padding-left: 20px;
  }
}

.termsContainer h1 {
  margin-bottom: 10px;
}

.termsContainer h2 {
  margin-bottom: 10px;
}

.listBullet {
  padding-left: 25px;
}
