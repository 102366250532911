.SnackbarAlertRoot {
  .gradient {
    border: solid 2px transparent;
    border-radius: var(--ld-app-box-border-radius);
    color: var(--ld-app-body-text-color-lightest);
    min-width: calc(100% - 3rem);
    background:
      linear-gradient(white, white) padding-box,
      linear-gradient(
          to right,
          rgb(222, 184, 168, 0.75),
          rgba(162, 1, 239, 0.5)
        )
        border-box;
    border: 2px solid transparent;
    box-shadow:
      0 10px 15px -3px rgb(0 0 0 / 0.1),
      0 4px 6px -4px rgb(0 0 0 / 0.1);

    @media screen and (min-width: 640px) {
      min-width: 320px;
    }
  }
}
