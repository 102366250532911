// define breakpoints as variables for better
$mobile: 540px;
$tablet: 600px;
$desktop: 960px;

@mixin responsive($breakpoint) {
  // $breakpoint is simply a variable that can have several values
  // start from mobile to large screen
  @if $breakpoint==mobile {
    @media only screen and (min-width: $mobile) {
      @content;
    }
  }

  @if $breakpoint==tablet {
    @media only screen and (min-width: $tablet) {
      @content;
    }
  }

  @if $breakpoint==desktop {
    @media only screen and (min-width: $desktop) {
      @content;
    }
  }
}
