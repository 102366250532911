.option {
  &:hover {
    cursor: pointer;
    background-color: #f6f6f6;
  }

  padding: 5px;
  justify-content: normal;
  font-size: 13px;
  margin: 1px;
  width: 250px;
  border-width: 10px;
  overflow-y: auto;
}

.boxnext {
  position: absolute;
  right: 10%;
  bottom: 10%;

  p {
    float: left;
    padding: 0px 15px;
    width: 250px;
  }
}

.chevronNext {
  float: right;
  vertical-align: bottom;
  background-color: #07232f;

  svg {
    color: white;
  }
}

.chevronNext:hover {
  border-radius: 100%;
  background-color: white;
  box-shadow: 0 0 5px 0 rgba(255, 255, 255, 0.25);

  svg {
    color: #07232f;
  }

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    border-radius: 50%;
    z-index: -1;
    animation: ripple 1.5s ease-out infinite;
  }
}

@keyframes ripple {
  from {
    opacity: 1;
    transform: scale(0);
  }
  to {
    opacity: 0;
    transform: scale(3);
  }
}
