.signinBox {
  display: flex;
  align-items: center;
  justify-content: center;

  * {
    font-family: 'Nunito', sans-serif;
  }
}

.signinBox2 {
  background: none;
  padding: 30px 15px;
  border-radius: 15px;
}

.signinHead {
  text-align: center;
  margin-bottom: 1.5rem;
}

.splogo {
  width: 130px;
  height: 130px;
  background: url('/images/logo-white.png') no-repeat center;
  margin: 0 auto;
  background-size: 90px;
}

.oathss {
  text-align: center;
  width: min(400px, 90vw);

  & button {
    background-color: rgba(255, 255, 255, 0.8) !important;
    text-align: center;
    height: 50px;
    border: 1px solid rgba(30, 30, 30, 0.5);
    font-size: 16px;
    border-radius: 8px;
    align-items: center;
    justify-content: center;
    display: flex;
    gap: 10px;
    font-family: var(--ld-app-font-family);

    & span:first-of-type {
      position: absolute;
      left: 5%;
    }

    // SigninWithPasswordForm button
    &.emailButton {
      height: 50px;
      background-color: var(--ld-app-hyperlink-font-color) !important;
      color: #fff;
      border-radius: 50px;
    }

    // google and faceebook
    &.oauthButton {
      color: #181818 !important;
      border-color: var(--ld-button-filled-secondary-border-color);
    }
  }

  // specific for SigninWithPasswordForm
  form {
    input {
      font-size: 1rem;
    }

    button {
      width: 36px;
      height: 36px;
      border: 1px solid rgba(30, 30, 30, 0.2);
    }
  }
}

.oauthLogo {
  width: 36px;
  height: 36px;
  background-size: cover;
  //background-color:white;
}

.logoApple {
  background-image: url(/images/apple-black.png);
  background-size: 28px 28px;
  background-position: center;
  background-repeat: no-repeat;
}

.logoSnapchat {
  background-image: url(/images/Snapchat.svg);
}

.divider {
  margin: 1rem 0;
  color: white;

  &::after,
  &::before {
    border-color: white !important;
  }
}

.bottomText {
  color: white !important;
  color: white !important;
  padding: 1rem 2rem;
  font-size: 14px;
  font-family: var(--ld-app-font-family);

  a,
  a:hover {
    color: white !important;
    text-decoration: underline;
  }
}

.titleText,
.subtitleText {
  font-family: var(--ld-app-font-family);
}

.titleText {
  //color: #4F2653;
  font-size: 28px;
  color: white;
}

.subtitleText {
  margin-bottom: 18px;
  text-align: center;
  color: white;
  font-size: 24px;
}

.emailControl {
  background: white;
  border-radius: 5px;
}

.iconButton {
  width: 50px;
  position: relative;
  border: 1px solid transparent !important;
}

.circularProgressIcon {
  position: absolute;
  left: auto !important;
  right: 10px;
  size: 30;
}

.buttonGroup {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 0 auto 16px auto;
  max-width: 450px;
  text-transform: capitalize;
}

.contactLink {
  cursor: pointer;
  //background-color: rgba(255, 255, 255, 0.8);
  margin: 0 auto;
  margin-top: 1rem;
  width: fit-content;
  border-radius: 32px;
  padding: 4px;
  background-image: url(/images/IconSupportWhite.svg);
  background-position: left;
  background-repeat: no-repeat;

  > div {
    padding-left: 24px;
    //background-color: black;
    //-webkit-mask-image: url(/images/support-services.svg);
    //mask-image: url(/images/support-services.svg);
    //background-size: cover;
    //height: 32px;
    //width: 32px;
  }
}
.divider {
  &::before,
  &::after {
    background-color: white !important;
  }
  p {
    color: white !important;
  }
}
