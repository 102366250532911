.CheckboxChip {
  display: flex;
  gap: 1px;
  align-items: center;

  .checked {
    z-index: 1;
    pointer-events: none;
    float: right;
  }
}

.CheckboxChipContent {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
