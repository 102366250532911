.currentValues {
  display: flex;
  flex-wrap: wrap;
  width: auto;
  //offsetting the padding of the chips so that it aligns well on the left side
  margin: 0px -8px 1rem;
}

.search {
  width: 100%;
  margin-bottom: 1rem;
}

.options {
  max-height: min(350px, 50vh);
  overflow-y: auto;
  margin-top: 0.5rem;
  background-color: #f5f6f7;
}

.listItem {
  margin: 0;
  cursor: pointer;

  &:hover {
    background-color: #fff;
  }
}

.inputChip {
  margin: 8px;
}
