.CustomizedSwitch {
  width: 52px;
  height: 28px;
  margin-right: 8px;
  font-size: 0;
  border-radius: 100%;
  position: relative;
  display: inline-block;
  cursor: pointer;

  input[type='checkbox'] {
    display: none;
  }

  //checked style
  &.isChecked {
    span[class='MuiSwitch-track'] {
      background:
        radial-gradient(
            126.42% 100% at 0% 100%,
            rgba(22, 169, 247, 0.4) 20%,
            rgba(0, 0, 0, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
            79.68% 79.68% at 0% 0%,
            rgba(255, 217, 69, 0.6) 0%,
            rgba(255, 217, 69, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
        radial-gradient(
          100% 100% at 100% 100%,
          rgba(162, 1, 239, 0.35) 0%,
          rgba(0, 0, 0, 0) 100%
        ),
        linear-gradient(
          0deg,
          rgba(252, 126, 255, 0.48),
          rgba(252, 126, 255, 0.48)
        ),
        #ffffff;
      background-color: white !important;
      opacity: 1;
      border-radius: 100px;
      left: -6px;
      position: relative;
      top: -5.5px;
      height: 35px;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px 0px rgba(0, 0, 0, 0.14),
        0px 1px 3px 0px rgba(0, 0, 0, 0.12);
    }

    span[class='MuiSwitch-thumb'] {
      display: block;
      width: 25px;
      height: 25px;
      top: 1.5px;
      left: 26px;
      border-radius: 16px;
      background-color: #fff;
      position: relative;

      transition-property: all;
      transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
      transition-duration: 120ms;
    }
  }

  //unchecked style
  span[class='MuiSwitch-track'] {
    background: #dcdcdc;
    border-radius: 16px;
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
  }

  span[class='MuiSwitch-thumb'] {
    display: block;
    width: 25px;
    height: 25px;
    top: 1.5px;
    left: 1px;
    border-radius: 16px;
    background-color: #fff;
    position: relative;

    transition-property: all;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transition-duration: 120ms;
  }

  // // span[class="MuiSwitch-focusVisible"][class="MuiSwitch-thumb"]{
  // //   background-color: #DCDCDC;
  // //   box-shadow: 0 0 1px 8px rgba(0, 0, 0, 0.25);
  // // }

  // span[class="Mui-checked"][class="MuiSwitch-track"]{
  //   background: blue
  // }
}
