.ProfilePicturesClickthrough {
  line-height: 0;
  height: 100%;
  background: #f4f4f4;

  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }

  position: relative;
}

.tapLeft {
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: 20%;
  cursor: w-resize;
}

.tapRight {
  cursor: e-resize;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 20%;
}
