@import 'lincd/lib/scss/variables.scss';

:root {
  --ld-slider-color: var(--ld-app-color-primary);
}

.slider {
  color: var(--ld-slider-color);
  border: none !important;
  width: 100%;
  height: 6px;
  padding: 16px 0;
  display: inline-block;
  position: relative;
  cursor: pointer;
  touch-action: none;
  -webkit-tap-highlight-color: transparent;

  &:hover {
    opacity: 1;
  }

  &.disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}

.rail {
  display: block;
  position: absolute;
  width: 100%;
  height: 6px;
  border-radius: 2px;
  background-color: #f7f7f8;
  left: 0;
}

.track {
  display: block;
  position: absolute;
  height: 4px;
  border-radius: 2px;
  background-color: var(--ld-slider-color);
}

.thumb {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-left: -6px;
  margin-top: -6px;
  box-sizing: border-box;
  border-radius: 50%;
  outline: 0;
  background-color: var(--ld-slider-color);
}

.mark {
  position: absolute;
  width: 4px;
  height: 4px;
  border-radius: 2px;
  background-color: var(--ld-slider-color);
  top: 50%;
  opacity: 0.7;
  transform: translateX(-50%);
}

.markActive {
  background-color: #fff;
}
