.divider {
  display: flex;
  align-items: center;
  // margin: 20px 0;
  --text-divider-gap: 1rem; // set a customizable gap between label and lines
  p {
    color: #c0c0c0;
  }
}

.divider::before,
.divider::after {
  content: '';
  height: 1px;
  background-color: #c0c0c0;
  flex-grow: 1;
}

.divider::before {
  margin-right: var(--text-divider-gap);
}

.divider::after {
  margin-left: var(--text-divider-gap);
}
