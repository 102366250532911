.LocationInput {
  ul {
    margin-top: 10px;
  }

  input[disabled] {
    cursor: not-allowed;
  }
}

.list {
  padding: 0;
}

.listItem {
  cursor: pointer;
}

.listItem:hover {
  cursor: pointer;
}

.locationResult {
  margin-top: -5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  //position: absolute;
  width: 100%;

  ul li:hover {
    background-color: #f5f5f5;
    border-radius: 10px;
    cursor: pointer;
  }
}

.addIconContainer {
  margin-top: 0.25rem;
}
