.ContainerSidebar {
  width: 100%;
  z-index: 9999;
  position: absolute;
  top: var(--safe-area-top, 0);
  left: -10% !important;
  // create css media queries for mobile only

  .navItem {
    flex: 1;
    display: flex;
    color: var(--ld-app-body-text-color);
    position: relative;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;

    &.active {
      color: var(--ld-app-hyperlink-font-color);
    }

    // reset notification component
    p {
      position: static;
    }
  }

  .overLay {
    background-color: rgba(0, 0, 0, 0.5);
    height: 100vh;
    width: 100%;
    position: fixed;
    left: 60px;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 9998;
    transition: background-color 0.3s ease-in-out;
  }

  .sidebarNavigation {
    position: absolute;
    left: -280px; // move the sidebar off-screen
    z-index: 0;
    width: 280px;
    opacity: 0;
    background-color: #ffffff;
    //transition: left 0.3s cubic-bezier(0.68, -0.55, 0.265, 1.55); // add transition to the left property with cubic-bezier timing function
    //slide in transition
    transition: left 0.15s ease-in-out;
    padding-top: var(--safe-area-top);
    padding-bottom: var(--safe-area-bottom);

    .listAccordName {
      list-style: none;
      padding: 0;
      margin: 0;
      margin-top: -10px;
      margin-left: -5px;

      > li {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        border-radius: 10px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
    .accordionSummary {
      width: 100%;
      .flexSide {
        margin-top: 30px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        display: flex;
        p {
          margin-top: 10px;
          padding-bottom: 20px;
        }
      }

      .listAccordName {
        list-style: none;
        padding: 0;
        margin: 0;
        // text-indent: -5px;
        li {
          display: flex;
          justify-content: flex-start;
          align-items: flex-start;
          border-radius: 10px;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
          }
          p {
            font-size: 16px;
            font-weight: 500;
          }
        }
      }
    }
    .topSidebar {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;
      cursor: pointer;

      h3 {
        margin-top: 20px;
        margin-bottom: 10px;
      }

      .MenuHeading {
        margin-top: 10px;
        margin-bottom: 10px;
      }

      .profileImage {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        object-fit: contain;
        background-size: contain;
        box-shadow: 0 0 4px rgba(0, 0, 0, 0.1);

        img {
          // object-position: 0 -50px;
          object-position: top;
        }
      }
    }
    .centerSidebar {
      background-color: #ffffff;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      padding: 0 20px;

      .RootAccordion {
        border: none;
        box-shadow: none;
        -webkit-box-shadow: none;
        margin-bottom: 0;
        margin-top: 0;
      }

      .centerSidebarItem {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 10px 20px;
        border-radius: 10px;
        cursor: pointer;
        img {
          width: 30px;
          height: 30px;
          margin-right: 10px;
        }
        p {
          font-size: 16px;
          font-weight: 500;
        }
      }
    }
  }

  // reset based style from accordion mui
  .accordionHeading {
    min-height: 0 !important;

    > div {
      margin: 5px 0;
    }
  }

  .flexRow {
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1;

    // reset notification component
    p {
      position: static;
    }
  }

  .open {
    opacity: 1;
    z-index: 99999;
    height: 100vh;
    overflow: scroll;
    top: 0;
    position: fixed;
    left: 0px !important; // move the sidebar on-screen
  }
  .closeBtn {
    position: absolute;
    top: calc(20px + var(--safe-area-top, 0));
    right: 0;
    color: #222;
    border: none;
  }
}
