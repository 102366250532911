.PopupRoot {
  z-index: 105;
  background: radial-gradient(
      129.3% 141.42% at -0% 100%,
      rgba(22, 169, 247, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      81.27% 109.03% at -0% 0%,
      rgba(255, 217, 69, 0.6) 0%,
      rgba(255, 217, 69, 0) 100%
    ),
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(
      0deg,
      rgba(252, 126, 255, 0.48) 0%,
      rgba(252, 126, 255, 0.48) 100%
    ),
    rgba(0, 0, 0, 0.5);

  // use safearea for handle space on mobile
  top: var(--safe-area-top);
  bottom: var(--safe-area-bottom);
}

.PopupWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: center;
  padding: 4rem 2rem;
  height: 100%;
}

.logo {
  margin-bottom: 1rem;
}

.description,
.subheading,
.heading,
.button {
  color: #fff;
  font-family: 'Nunito';
}

.subheading,
.heading {
  font-weight: 700;
  text-shadow: 0px 3px 10px rgb(0 0 0 / 0.25);
  line-height: 1.2;
}

.description {
  font-size: 24px;
  line-height: 1.5;
  margin-bottom: 3rem;
}

.subheading {
  font-size: 32px;
  margin-bottom: 0.25rem;
}

.heading {
  font-size: 48px;
}

.profileCard {
  max-height: 20vh;
}

.button {
  font-size: 1rem;
  //font-weight: 500;
  //text-transform: none;
  //display: inline-flex;
  //align-items: center;
  //justify-content: center;

  &:hover {
    svg {
      transform: translateX(10px);
    }
  }

  svg {
    height: 20px;
    margin-left: 5px;
    transition: all 0.2s ease-in-out;
  }
}
