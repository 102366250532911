.PlaceChip {
  //vertical-align: middle !important;
  //
  background-color: #f3f3f3 !important;
  //padding: 17px 6px;
  font-size: 16px;
  font-family: 'Nunito', sans-serif;
  display: inline-flex;
  // svg {
  //display: inline-block;
  //vertical-align: baseline;
  // }
  //div {
  //  display:inline-block;
  //  vertical-align: bottom;
  //}
}
