.Admin {
  //shows profiles on the left of the page and profile details on the right
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 1rem;
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  box-sizing: content-box;
  background: white;
  overflow: scroll;
  position: fixed;
  padding: 1rem;

  > div {
    height: 100vh;
    overflow-y: scroll;
  }
  .profiles {
    display: grid;
    //auto fit the columns to the width of the page
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    overflow: hidden;
    width: 100%;
    grid-gap: 1rem;
    margin: 1rem;
    height: min-content;

    > * {
      cursor: pointer;
    }
    > div {
      max-width: 100%;
      p {
        word-break: break-word;
      }
    }
  }

  .profileDetail {
    margin: 1rem;

    pre {
      overflow: scroll;
      padding: 1rem;
      background: white;
    }

    > * {
      margin: 1rem;
    }
  }
}
