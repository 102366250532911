.PlacePreview {
  border-radius: 6px;

  &.default {
    background-color: #f1f5f9;
    margin-bottom: 2px;
    color: var(--ld-app-body-text-color);

    span {
      background: white;
      padding: 10px;
      border-radius: 12px;
    }
  }

  &.small {
    span {
      padding: 4px;
    }
  }

  button {
    background: transparent;
  }

  .removeIcon {
    cursor: pointer;
  }
}

.placePreviewText {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}
