.root {
  padding-left: 15px;
  padding-right: 15px;
  position: relative;
}

.EditProfile {
  padding-bottom: 100px;
  padding-top: 80px;
}

.headerProfile {
  margin-bottom: 1.5rem;
}

.signOutButton {
  margin-top: 1.5rem;
  width: 100%;
}
