:root {
  --inputchip-border-color-hover: var(--ld-app-color-tertiary-lighter);
  --inputchip-border-color: var(--ld-ref-palette-neutral90);
  --inputchip-border-color-active: var(--ld-app-color-tertiary-lighter);
}
.InputChip {
  //seemly we need important to overwrite focus/active state
  background-color: white !important;
  border: 1px solid var(--inputchip-border-color);
  color: var(--ld-app-body-text-color);
  font-size: 0.8rem;

  cursor: pointer;
  padding: 5px 10px;
  height: fit-content;
  min-height: 34px;
  background-color: #ffffff;
  border-radius: 17px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:not(.no_hover_effect):hover {
    //border: 2px solid $primaryColorLight !important;
    border: 2px solid var(--inputchip-border-color-hover) !important;
  }

  &.chip_selected {
    border: 2px solid var(--inputchip-border-color-active);

    & svg {
      color: var(--inputchip-border-color-active);
    }
  }

  .icon {
    float: right;
  }
}
