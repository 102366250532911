// Note:
// currently the button component has minimal style
// if your style variant or color is not available, just update it here

.ButtonRoot {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: 'Nunito';
  font-size: 1rem;
  line-height: 1.5;
  background-color: transparent;
  color: var(--ld-app-body-text-color);
  border-radius: var(--ld-app-box-border-radius);
  font-weight: 400;
  padding: 8px 16px;
  position: relative;

  &:hover,
  &:focus {
    cursor: pointer;
  }

  &:active {
    box-shadow: 0 1rem 1rem rgba(var(--ld-ref-palette-neutral-variant50), 0.2);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.fullwidth {
    width: 100%;
  }

  &.contained {
    color: white;
    border: none;
    border-radius: var(--ld-ref-border-radius-xl);

    &.primary {
      background-color: var(--ld-ref-palette-primary80);
      //background-color: $primaryBackground;
      transition: all 0.2s;

      &:hover {
        //background-color: $primaryBackgroundHover;
        background-color: var(--ld-ref-palette-primary70);
      }
    }

    &.secondary {
      //background-color: $secondaryBackground;
      background-color: var(--ld-ref-palette-neutral-variant20);
      transition: all 0.2s;

      &:hover {
        //background-color: $secondaryBackgroundHover;
        background-color: var(--ld-ref-palette-neutral-variant30);
      }
    }
  }

  &.outlined {
    border: solid 1px var(--ld-ref-palette-neutral-variant80);
    background-color: #fff;

    &.primary {
      background:
        linear-gradient(white, white) padding-box,
        linear-gradient(
            170deg,
            rgba(255, 217, 69, 0.6),
            rgba(252, 126, 255, 0.4),
            rgba(162, 1, 239, 0.35)
          )
          border-box;
      border-radius: var(--ld-app-box-border-radius);
      border: 2px solid transparent;
    }

    &.secondary {
      //background-color: $secondaryColor;
      background-color: var(--ld-ref-palette-primary80);
      color: white;
      border-radius: 30px;
      border: none;
    }
  }

  &.text {
    border: none;
  }
}
