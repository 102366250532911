.ChatOverview {
  // padding-top: 80px;

  * {
    font-family: 'Nunito', sans-serif !important;
  }
}

.ChatConversation {
  [class='cc-messages__header'] {
    position: relative;
    right: 0px;

    //based on the default position of the back button, this height makes sure the button & the avatar are aligned
    height: 68px !important;
    //this gives exactly 20px of space on the left between the back button and the avatar
    margin-left: 60px;
    width: calc(100% - 62px) !important;

    // @media (max-width: 680px) {
    //   margin-left: 48px;
    // }
  }

  [class='cc-messages'] {
    justify-content: normal !important;
  }

  [class='cc-list__header'] {
    display: none;
  }

  // [class='cc-messages-wrapper'] {
  //   height: calc(100vh - 150px) !important;
  // }

  //hide the info icon
  [class='cc-message-header__menu'] {
    display: none !important;
  }

  [class='cc-messages__wrapper'] {
    //margin: 0 -15px;
    width: 100vw !important;
    left: 0;
    position: fixed !important;
  }

  //doesnt work because its in shadow dome
  //[class='cc-messageinput-input'] {
  //  font: inherit;
  //  padding: 8px 7px;
  //}
  [class='cc-messages__composer'] {
    width: 100vw !important;
    //height: auto !important;
    height: 153px !important;

    position: fixed;
    bottom: var(--safe-area-bottom);
    left: 0;
    border-radius: 0;
    border-top: 1px solid #e3e3e3;

    > div {
      padding: 12px !important;
      //bottom: var(--safe-area-bottom);
      position: relative;
      width: 100% !important;
      padding-bottom: 38px !important;
    }

    > cometchat-divider {
      display: none;
    }
  }

  .headerOverlay {
    position: absolute;
    left: 70px;
    right: 20px;
    height: 70px;
    background: none;
    cursor: pointer;
    z-index: 100;
  }
}

.MessageOverview {
  padding-top: 4.5rem;
}

.flexSide {
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  align-items: center;

  img {
    position: absolute;
    height: 32px;
    width: 32px;
    right: 30px;
    top: 25px;
    display: block;
    z-index: 9999;
  }
}

.reportUser {
  position: absolute;
  margin-top: 20px;
  right: 10px;
}
.reportedInput {
  display: inline;
  margin-top: 20px;
  margin-bottom: 1rem;
  // height: 23px;
  textarea {
    overflow: hidden;
    border: 1px solid #c8c8c8;
    padding: 5px 10px 1.5rem;
    border-radius: 5px;
    box-sizing: border-box;
  }
}
.reportUser,
.blockUser {
  margin-right: 5px;
  height: 32px;
  width: 32px;
}

.reportUserPopup {
  position: absolute;
  margin-top: 20px;
  right: 10px;
  button {
    margin-right: 10px;
  }
}

.chatListUser {
  display: block;
  :hover {
    background: #f5f5f5;
    cursor: pointer;
  }
  .chatInner {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    flex-direction: row;
    .chatInnerFlex {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }
    img {
      margin-right: 10px;
    }
    .lastMessage {
      color: #666;
      font-size: 12px;
    }
  }
}

.pageHeaderChat {
  margin-top: -45px;
  padding-bottom: 20px;
  font-size: 1.4rem;
  font-weight: 600;
  text-align: center;
}

.reportedInput {
  margin-top: 40px;
}

.backButtonChat {
  top: calc(8px + var(--safe-area-top));
  z-index: 20;
}

.chatPageRoot {
  position: relative;
}

.detailPerson {
  padding: 10px;
}
