@import '../scss//variables-components.scss';

.CouponContainer {
  width: 100vw;
  height: 100vh;
  font-family: 'Arial', sans-serif;
  position: absolute;
  left: 0;
  box-sizing: content-box;
  background: white;
  overflow: scroll;
  position: fixed;
  .headerDashboard {
    border: 1px solid #eee;
    box-shadow: 0 2px 11px 4px #eee;
    display: flex;
    background-color: #fff;
    border-radius: 5px;
    padding: 25px;
    flex-direction: row;
    align-items: center;
    .menuHeader {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 4rem;
      justify-content: space-between;
      gap: 40px;
      h3:hover {
        cursor: pointer;
      }
    }
    .headerLogo {
      display: flex;
      flex-direction: row;
      align-items: center;
      h2 {
        margin-top: 0;
        margin-bottom: 0;
      }
      img {
        width: 32px;
        height: 32px;
        margin-right: 15px;
      }
    }
  }
  .CouponInner {
    padding: 20px;
    padding-top: 0;
    .listCoupon {
      display: block;
      button {
        margin-left: 1rem;
      }
    }
    .pagination {
      margin-top: 1rem;
      button {
        margin-left: 0;
        color: #222;
        background-color: #fff !important;
      }
      span {
        margin: 0 10px;
      }
    }
    .successCoupon {
      display: flex;
      flex-direction: row;
    }
    table {
      width: 98%;
      border-collapse: collapse;

      .filtered {
        display: flex;
        align-items: center;
        width: 100%; /* Set the width to 100% */
        margin-bottom: 1rem;
      }

      .formTabel {
        margin-right: 1rem;
        width: 100%; /* Set width to 100% */

        .filterTable {
          display: block;
        }
      }
      th,
      td {
        border: 1px solid #dddddd;
        text-align: left;
        padding: 8px;
      }

      padding: 25px;

      .codeInput {
        display: flex;
        flex-direction: row;
        gap: 1rem;
        margin: 1rem;
      }

      .divider,
      .couponList {
        margin-top: 1rem;
      }

      .couponList {
        .timesRedem {
          margin-left: 10px;
        }
        ul {
          margin-top: 1rem;
          li {
            margin-top: 5px;
            div {
              display: flex;
              flex-direction: row;
              align-items: center;
              img {
                width: 30px;
                height: 30px;
                background: #fff;
                padding: 2px;
                border-radius: 4px;
                border: 1px solid #ccc;
                margin-left: 10px;
              }
              button {
                margin-left: 10px;
              }
            }
            img:hover,
            button:hover {
              cursor: pointer;
              background-color: #eee;
            }
          }
        }
      }
    }
    .resultFilter {
      margin-left: 15px;

      .resultSelected {
        display: flex;
        flex-direction: row;
        align-items: center;
        h4 {
          width: 120px;
        }
        p {
          margin-left: 10px;
        }
      }
    }
  }
  button {
    margin-left: 1rem;
  }
  h2 {
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
  .odd {
    background-color: #f2f2f2;
  }
}
