:root {
  --radiochip-border-color-active: var(--ld-app-color-active);
}
.RadioChipRoot {
  margin-bottom: 1rem;
  display: flex;
  gap: 1px;
  align-items: center;
  width: 100%;
  flex-direction: column;
  white-space: pre-line;
  cursor: default;

  .radioChipContainer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    display: flex;
    justify-content: flex-start;
  }

  .radiochip_chip {
    padding: 10px 20px;
    width: 100%;
    height: fit-content;
    min-height: 51px;
    background-color: #ffffff;
    border-radius: 12px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    h3 {
      margin-bottom: 5px;
    }

    > span {
      overflow: visible !important;

      &:first-of-type {
        /* Auto layout */
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 0px;
        gap: 8px;
        height: 24px;
        /* Inside auto layout */
        flex: none;
        order: 0;
        flex-grow: 1;
        max-width: 100%;
      }
    }

    &.selected {
      //border: 2px solid $primaryColor;
      border: 2px solid var(--radiochip-border-color-active);

      & svg {
        color: var(--radiochip-border-color-active) !important;
      }
    }
  }

  .radiochip_chip:hover:not(.selected) {
    border: 2px solid var(--radiochip-border-color-active);
    background-color: #ffffffaa;

    > span:first-of-type {
      //color: white;
    }

    & svg {
      color: var(--radiochip-border-color-active);
    }
  }

  .checked {
    z-index: 1;
    pointer-events: none;
  }
}
