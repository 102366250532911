@import '../scss/variables-theme.scss';
@import '../scss/variables-components.scss';

:root {
  --sp-filter-container-border-primary: solid 1px
    var(--ld-ref-palette-neutral80);
  --sp-filter-container-border-secondary: solid 2px
    var(--ld-ref-palette-secondary70);
  --ld-app-color-active: var(--ld-app-color-secondary-darker);
}

.ContainerSidebar {
  width: 100%;
  z-index: 9999;
  top: var(--safe-area-top, 0);
  right: 0;
  position: absolute;
  background-color: #fff;
  border-radius: 30px;
  color: var(--ld-paragraph-text-color-primary);

  .filterSidebar {
    position: fixed;
    top: var(--safe-area-top, 0);
    z-index: 0;
    width: min(600px, 100vw);
    height: 100vh;
    opacity: 0;
    transition: all 0.15s ease-in-out;
    transform: translateX(600px);
    right: auto;
    visibility: hidden;

    &.open {
      opacity: 1;
      z-index: 200;
      transform: translateX(0);
      visibility: visible;
    }

    .bigContainer {
      padding: 0 30px 30px 30px;
      width: 100%;
      height: 100vh;
      overflow: scroll;
      > h4 {
        margin: 10px 0 10px 0;
      }
    }
    .slider {
      border: var(--sp-filter-container-border-primary);
      border-radius: var(--ld-app-box-border-radius);
      padding: 15px 30px 15px 25px;
      display: flex;
      flex-direction: column;
      font-size: 0.8rem;
      span[class='MuiSlider-thumb'] {
        background-color: var(--ld-slider-color);
      }
      span[class='MuiSlider-rail'] {
        position: absolute;
        left: 0;
      }
      span[class='MuiSlider-track'] {
        background-color: var(--ld-slider-color) !important;
      }
    }

    .topSidebar {
      text-align: center;
      padding: 15px 20px;
    }

    .centerSidebar {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      height: 100vh;
      width: 100%;
    }
    .radioChip {
      --inputchip-border-color-hover: var(--ld-app-color-secondary-darker);
      --inputchip-border-color-active: var(--ld-app-color-secondary-darker);
      --radiochip-border-color-active: var(--ld-app-color-secondary-darker);
    }
  }

  .closeBtn {
    position: absolute;
    top: 10px;
    left: 0;
    background-color: transparent;

    &:hover,
    &:focus {
      background-color: transparent;
    }
  }
}

.applyBtn {
  border-radius: var(--ld-ref-border-radius-m);
  margin-top: 1rem;
}
