$actionsheet-background-color: #fff;
$actionsheet-text-color: #333;
$actionsheet-border-radius: 10px;
$actionsheet-padding: 20px;
$actionsheet-button-background-color: #f5f5f5;
$actionsheet-button-text-color: #333;
$actionsheet-button-border-radius: 5px;
$actionsheet-button-padding: 10px;

.action_sheet_overlay {
  position: fixed;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  margin: 0 auto;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
  animation: slide-up 0.15s ease-out;
  animation-delay: 0s;
  animation-fill-mode: forwards;
  transform: translateY(100%);
  display: flex;
  align-items: flex-end;

  @media (min-width: 600px) {
    width: 600px;
  }

  .show {
    animation-delay: 0s;
    animation-fill-mode: forwards;
    animation-play-state: running;
    transform: translateY(0);
  }

  .action_sheet {
    background-color: $actionsheet-background-color;
    border-top-left-radius: $actionsheet-border-radius;
    border-top-right-radius: $actionsheet-border-radius;
    position: relative;
    max-height: 90vh;
    overflow: auto;
    flex: 1;
    padding-bottom: 1rem;
    box-shadow: 0px 5px 15px #00000033;

    .action_sheet_content {
      max-width: 600px;
      margin: 0 auto;
      padding: 1rem;

      &.isFullwidth {
        max-width: 100%;
      }
    }

    .action_sheet_button {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 25px;
      left: 25px;
      background: none !important;
      border: none !important;

      button {
        background-color: none;
        color: $actionsheet-button-text-color;
        border-radius: $actionsheet-button-border-radius;
        padding: $actionsheet-button-padding;
        margin-bottom: 10px;
        cursor: pointer;
        border: none;
      }
    }
  }
}

@keyframes slide-up {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}
