.MultiSelectContainer {
  display: grid;
  min-width: 80%;
  grid-template-columns: repeat(3, 0.25fr);
  grid-gap: 8px;
}

// .label {
//     width:fit-content;
// }
