.QuestionContainer {
  padding: 16px 24px;
  color: white;

  &:not(:first-of-type) {
    padding-top: 2rem;
  }

  &:first-of-type {
    padding-top: 2.5rem;
  }

  &:last-of-type {
    padding-bottom: 150px;
  }
}
