@import '../scss/media-query';

.root {
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  p {
    text-align: center;
    font-family: 'Nunito', sans-serif;
  }
}

.wrapper {
  padding-top: 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;

  @include responsive(tablet) {
    padding-top: 100px;
  }

  .eventContainer {
    display: flex;
    width: 324px;
    border-radius: 24px;
    img {
      border-radius: 16px;
    }
  }

  iframe {
    width: 100%;
    height: 100vh;
    border: none;
  }
}
