.ProfileLayoutContainer {
  flex-grow: 1;
}

.ProfileLayoutContent {
  position: static;
}

.IconButton {
  margin-right: 0;
  font-size: large;
  color: inherit;
}

.layoutContainer {
  margin-top: 57px;
  @media (min-width: 600px) {
    margin-top: 65px;
  }
}
