@import '../scss/media-query';

.root {
  position: relative;
  padding-bottom: 50px;
}

.matchesContainerBox {
  padding-top: 90px;
  // min-height: 0;
  // height: 100vh;
  padding-left: 1rem;
  padding-right: 1rem;

  @include responsive(tablet) {
    padding-top: 100px;
  }
}

.matchesContainer {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  align-items: center;
  justify-items: center;
  max-width: 1080px;
  font-family: 'Nunito';
  gap: 8px;
  margin-bottom: 66px;
  height: 100%;
  grid-column-gap: 8px;

  @include responsive(tablet) {
    gap: 1rem;
    grid-column-gap: 1rem;
  }
}

.tabsList {
  display: flex;
  justify-content: space-around;
  background-color: transparent;
  border: none;
  align-items: center;
  margin-bottom: 5px;
}

.tabListing {
  background-color: transparent;
  border: none;
  font-size: 1.1rem;
  font-weight: 600;
  color: #00201f;
  cursor: pointer;
  padding: 0;
  outline: none;
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  position: relative;
  font-family: 'Nunito', sans-serif;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 3px;
    background-color: transparent;
    border-radius: 3px 3px 0 0;
  }

  &.tabActive {
    color: #008c8a;

    &:after {
      background-color: #008c8a;
    }
  }

  img {
    width: 20px;
    margin-right: 0.5rem;
  }
}

.tabContent {
  padding-top: 10px;
}

.matchPreview {
  //border-radius:25px;
  background-color: white;
  position: relative;
  border-radius: 16px;
  overflow: hidden;
  width: 100%;
  max-height: 250px;
  cursor: pointer;
  aspect-ratio: 3 / 5;
  display: flex;
  box-shadow: 0px 2px 2px 2px rgba(0, 0, 0, 0.07);

  @include responsive(tablet) {
    max-height: 350px;
  }

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  h1 {
    font-family: 'Nunito', sans-serif;
  }
}

.matchCardImageWrap {
  line-height: 0;
  width: 100%;
  display: flex;
  line-height: 0;
  width: 100%;
  display: flex;
}

.matchImage {
  object-fit: cover;
  max-height: 350px;
  background: radial-gradient(
      126.42% 100% at 0% 100%,
      rgba(22, 169, 247, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      79.68% 79.68% at 0% 0%,
      rgba(255, 217, 69, 0.6) 0%,
      rgba(255, 217, 69, 0) 100%
    ),
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 4px solid transparent;
  width: calc(100% - 8px);
  border-radius: 30px;
  object-fit: cover;
  max-height: 350px;
  background: radial-gradient(
      126.42% 100% at 0% 100%,
      rgba(22, 169, 247, 0.4) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    radial-gradient(
      79.68% 79.68% at 0% 0%,
      rgba(255, 217, 69, 0.6) 0%,
      rgba(255, 217, 69, 0) 100%
    ),
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)),
    linear-gradient(0deg, #ffffff, #ffffff);
  border: 4px solid transparent;
  width: calc(100% - 8px);
  border-radius: 30px;
}

.newMatch {
  object-fit: cover;
  // height: calc(278px + 40vh);
  max-height: 350px;
  // width: calc(175px + 25vw);

  background:
    radial-gradient(
        126.42% 100% at 0% 100%,
        rgba(22, 169, 247, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        79.68% 79.68% at 0% 0%,
        rgba(255, 217, 69, 0.6) 0%,
        rgba(255, 217, 69, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)),
    linear-gradient(0deg, #ffffff, #ffffff);
  background:
    radial-gradient(
        126.42% 100% at 0% 100%,
        rgba(22, 169, 247, 0.4) 0%,
        rgba(0, 0, 0, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
        79.68% 79.68% at 0% 0%,
        rgba(255, 217, 69, 0.6) 0%,
        rgba(255, 217, 69, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */,
    radial-gradient(
      100% 100% at 100% 100%,
      rgba(162, 1, 239, 0.35) 0%,
      rgba(0, 0, 0, 0) 100%
    ),
    linear-gradient(0deg, rgba(252, 126, 255, 0.48), rgba(252, 126, 255, 0.48)),
    linear-gradient(0deg, #ffffff, #ffffff);

  // new match
  --matches-border-width: 2px;
  border: var(--matches-border-width) solid transparent;
  width: calc(100% - (var(--matches-border-width) * 2));
  border-radius: 30px;
  box-sizing: border-box;
  height: 65vw;
}

.matchText {
  font-weight: 700;
  color: white;
  margin-left: 1rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: white;
  font-size: 16px;
}

.matchSmallText {
  color: white;
  font-weight: 400;
  margin-left: 1rem;
  font-size: 1rem;
  color: white;
  font-weight: 400;
  font-size: 14px;
}

.matchPercentageContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.matchIcon {
  z-index: 1;
  bottom: 37%;
  right: 8%;
  width: calc(45px + 2vw);
  z-index: 1;
  bottom: 37%;
  right: 8%;
  width: calc(45px + 2vw);
}

.matchPercentage {
  z-index: 1;
  bottom: 45%;
  right: 10%;
  color: white;
  z-index: 1;
  bottom: 45%;
  right: 10%;
  color: white;
}

.matchDataContainer {
  z-index: 3;
  background-color: grey;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) 8.33%,
    rgba(0, 0, 0, 0.35) 17.19%
  );
  border-radius: 0 0 30px 30px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 0;
  width: inherit;
  padding: 10px 8px 8px 8px;
}

.ProfileCardImageContainer {
  line-height: 0;
  height: calc(100vh - 188px);

  img {
    margin-top: 5px;
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 20px;
  }
}

.modalContainer {
  //100vh, minus 3 on the top and 3 at the bottom = 94, minus 58px for the footer + 10px padding
  height: calc(94vh - 58px - var(--safe-area-top) - var(--safe-area-bottom));
  width: 90vw;
  background: white;
  margin: 0 auto;
  overflow-y: scroll;
  margin-top: calc(3vh + var(--safe-area-top));
  padding: 1rem;
  position: relative;
  max-width: 600px;
  border-radius: var(--ld-ref-spacing-l);
}

.modalFooter {
  position: fixed;
  bottom: calc(3vh + var(--safe-area-bottom));
  left: 50%;
  margin: 0 auto;
  width: 90vw;
  max-width: 600px;
  transform: translateX(-50%);

  > * {
    border-radius: 24px;
    width: 48px;
    height: 48px;
    background: white;
    cursor: pointer;
    box-shadow: 0px 2px 2px 3px rgba(0, 0, 0, 0.07);
    font-size: 1.2rem;
    //center the icon inside
    display: inline-flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    //margin-right: 5px;

    &:last-child {
      float: right;
      //margin-right:0;x
    }
  }
}

.emptyList {
  text-align: center;
  font-family: 'Nunito', sans-serif;
  margin-top: 2rem;
  padding: 0 1.5rem;
}
