@import '../scss/media-query';

.SwipeProfilesRoot {
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

.yellowColor {
  color: #e7b822;
}

.SwipeProfiles {
  position: relative;
  //32px padding at the bottom for the box in Content, which the parent container of swipe profiles is within
  //66px is the height of the footer
  //TODO: replace these with a single variable in both places where these numbers are used
  // padding-bottom: calc(66px - 32px);
  //min-height:89vh;
  // min-height: calc(100vh - 24px - 66px);
}

.upgradeAccountLoadingOverlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.offset {
  // left: -8px;
  position: relative;
}

.PreviewContainer {
  z-index: 1000000000;
  position: fixed;
  height: calc(100vh - 188px - 26px);

  //NOTE: currently the only container we use is fixed to 600px. We also use 15px padding on this container, and 10px padding on the SliderContainer
  //TODO: calculate this based on variables or actually measure the width of a (draggable) profile card on the screen
  width: min(550px, 100vw - 50px);

  .PreviewCard {
    height: 100%;
    width: 100%;
    border-radius: 20px;
  }
}

.currentProfileCardContainer {
  height: calc(100vh - 188px);

  > * {
    height: 100%;
  }
}

.smallButtonsGroup {
  flex-direction: column;
  display: flex;
  position: absolute;
  right: 10px;
  bottom: 80px;
  gap: 1rem;

  @media (min-width: 560px) {
    right: 30px;
  }
}

.swipeMenu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // position: absolute;
  position: fixed;
  left: 0;
  right: 0;
  // bottom: 10px;
  bottom: calc(1rem + var(--safe-area-bottom));
  z-index: 11;
  max-width: 600px;
  margin: 0 auto;
}

.bigButtonsGroup {
  position: absolute;
  //same as ProfileCard height
  bottom: var(--ld-ref-spacing-l);
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-bottom: 1rem;
  transition: all 0.5s;
  transform: scale(1);

  .pendingLike,
  .pendingDislike,
  .pendingDetails {
    transition: all 0.5s;
    transform: scale(1.35);
  }
  button {
    padding: 0 !important;
  }

  button:hover {
    background-color: inherit !important;
  }
}

.SliderContainer {
  margin: 0 auto;
  max-width: 1000px;
  overflow-x: hidden;
}

.outOfCards {
  padding-top: 100px;
  padding-left: 2rem;
  padding-right: 2rem;
  transition: opacity 0.15s;

  h2 {
    margin-bottom: 1.5rem;
    font-family: 'Nunito', sans-serif;
  }

  p,
  a {
    font-family: 'Nunito', sans-serif;
  }
}

.loading,
.outOfCards {
  text-align: center;
  font-size: 20px;
}

.draggableProfile {
  position: absolute;
  z-index: 11;
  top: 0;
  left: 0;
  border-radius: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s;
  cursor: grab;
  // height: 100vh;
  height: calc(100vh - var(--safe-area-top, 0) - var(--safe-area-bottom, 0));

  &.below {
    z-index: 9;
  }
}

.buttonContainer {
  padding: auto 10px;
}

.dragging {
  opacity: 0%;
  transition:
    opacity,
    display 0.15s;
  display: none;
}

.dropped {
  opacity: 100%;
}

.DropTargetDisLike {
  position: fixed;
  left: 0;
  width: calc(50vw - 75px);
  height: 100%;
}

.DropTargetLike {
  position: fixed;
  right: 0;
  width: calc(50vw - 75px);
  height: 100%;
}

.DropTargetNone {
  position: absolute;
  left: calc(50% - 75px);
  width: 150px;
  height: 50%;
  top: 50%;
}

.DropTarget {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.DropTargetShowDetails {
  position: absolute;
  left: calc(50% - 75px);
  width: 150px;
  height: 50%;
  top: 0;
}

.MainProfileIcon {
  display: flex;
  gap: 5px;
  align-items: center;
  color: white;

  img {
    filter: invert(1);
  }
}

.MultipleProfileDetail {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.DislikeIcon,
.LikeIcon {
  color: white;
  font-size: 30px;
  border-radius: 50%;
  background-color: #a201ef;
  opacity: 60%;
  padding: 20px;
}

.ProfileCardImageContainer {
  line-height: 0;
  height: 100%;
  background: #f4f4f4;

  img {
    max-width: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center center;
  }
}

.ProfileCardTextContainer {
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.15) 8.33%,
    rgba(0, 0, 0, 0.35) 17.19%
  );
  color: white;
}

.LocationContainer {
  display: flex;
  gap: 8px;
}

.countdown {
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 45;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.countdownImage {
  height: 130%;
  width: 130%;
  fill: #4ddad7 !important;
  filter: brightness(85%);
}

.boostContainer,
.superLikeContainer {
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.boostText,
.superLikeText {
  color: white;
  font-size: 14px;
  white-space: nowrap;
  line-height: 1.2;
}

.boostActivated {
  animation: svg-shadow 1.5s ease-in-out infinite alternate;
  fill: #4ddad7;
}
.featureDisabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}
.boostDisactivated {
  fill: #4ddad7;
}

.moreFeatureBtn {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.swipeTap {
  position: absolute;
  height: calc(
    100vh - var(--safe-area-top, 0px) - var(--safe-area-bottom, 0px)
  );
  width: 150px;
  max-width: 600px;
  margin: 0 auto;
  z-index: 0;

  &.swipeTapLeft {
    left: 0;
  }

  &.swipeTapRight {
    right: 0;
  }
}
