.rulesbox {
  color: #fff;
  background-image: url('images/HomeBackgroundLarge.avif') !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 1rem;
}

.rulesbox2 {
  color: #000;
  background: rgba(255, 255, 255, 0.8);
  padding: 24px 16px;
  border-radius: 15px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  margin-bottom: 100px;

  p {
    color: rgba(0, 0, 0, 0.6);
  }
}

.splogo {
  width: 80px;
  margin: auto;
  display: block;
  height: 80px;
  background: url('/images/logo-white.png') no-repeat center;
  background-size: cover;
  margin: 0.8rem auto;
}

.bold {
  font-weight: bold;
}

.title {
  text-align: center;
  color: #fff;
  font-family: 'Nunito', sans-serif;
  font-size: 28px;
  margin-bottom: 0.8rem;
}

.agreeButton {
  background-color: var(--ld-ref-palette-primary80) !important;
  color: white !important;
  border-radius: 30px;
  border: none;
  margin: 1rem;
  width: 90%;
  position: absolute;
  bottom: calc(1rem + var(--safe-area-bottom));
  // add max with to avoid button to be too wide on desktop
  max-width: 540px;
}
