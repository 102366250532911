.container {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 30px;
  height: 50px;
  padding: 8px 14px 8px 4px;
  font-variant: tabular-nums;
}
