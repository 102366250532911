@import '../../scss/media-query';

.headerBarContainer {
  z-index: 100;
  position: fixed;
  width: 100%;
  top: var(--safe-area-top);
  left: 0;
  right: 0;
  padding: 10px 15px;
  max-width: 600px;
  margin: 0 auto;
  transition: all 0.15s ease-in-out;

  @include responsive(tablet) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &.moreVerticalSpace {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  &:hover {
    cursor: pointer;
  }

  // active class when scroll
  &.active {
    background-color: rgba(255, 255, 255, 0.95);
    padding-top: 3px;
    padding-bottom: 3px;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.05);

    .headerBar {
      .headerBarLeft,
      .headerBarRight {
        background-color: transparent;
      }
    }
  }

  .headerBar {
    display: flex;
    // top: 10px;
    justify-content: space-between;
    align-items: center;
    z-index: 1;

    .headerBarCenter {
      right: 30px;
      text-transform: capitalize;

      h3 {
        font-family: 'Nunito', sans-serif;
        font-weight: 600;
      }
    }

    .headerBarLeft,
    .headerBarCenter,
    .headerBarRight {
      // position: relative;
      // z-index: 1;
      align-items: center;
      justify-content: center;
      display: flex;
      //h3 {
      //color: #fff;
      //}

      .onBackButton {
        height: 18px;
        width: 18px;
      }
    }

    // add transition active when scroll
    .headerBarLeft,
    .headerBarRight {
      transition: all 0.15s ease-in-out;
    }

    .headerBarRight {
      right: 0px;
      background-color: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      padding: 20px;
      height: 50px;
      width: 50px;

      .tripleDots {
        fill: white;
        height: 20px;
      }
    }
    .headerBarLeft {
      background: rgba(255, 255, 255, 0.7);
      border-radius: 50%;
      width: 50px;
      height: 50px;
      padding-top: 15px;
      display: block;
      text-align: center;

      &.onBack {
        padding-top: 11px;

        .logo {
          width: 28px;
        }
      }
    }

    .logo {
      width: 20px;
      position: relative;
    }

    .logo_sp {
      width: 38px;
      height: 38px;
      border-radius: 50%;
      object-fit: cover;
      margin-right: 20px;
    }
  }
}

.offset {
  left: 0px;
}

.emptyIcon {
  width: 50px;
  display: block;
}
